export default {
  methods: {
    getStandardDeviation(data) {
      let mean = this.getMean(data);
      let varData = data.map((item) => Math.pow(item.value - mean, 2));
      let variance = varData.reduce((acc, val) => acc + val, 0) / data.length;

      return Math.sqrt(variance) || 0;
    },
    getMean(data) {
      let val = data
        .map((item) => item.value)
        .reduce((acc, val) => acc + val, 0);

      return val / data.length;
    },
    getCoeffVariation(data) {
      let mean = this.getMean(data);
      let stnDev = this.getStandardDeviation(data);
      return stnDev / mean || 0;
    },

    getMinValue(lower, data, key = "value") {
      let min = 111111;
      data.forEach((item) => {
        let value = parseInt(item[key]);
        if (value < min) {
          min = value;
        }
      });

      return min < lower ? min : lower;
    },
    getMaxValue(upperValue, data, key = "value") {
      let max = -111111;
      data.forEach((item) => {
        let value = parseInt(item[key]);
        if (value > max) {
          max = value;
        }
      });

      return max > upperValue ? max : upperValue;
    },
  },
};
