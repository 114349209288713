import Highcharts from "highcharts";
import jsPDF from "jspdf";
// import axios from 'axios'
// Load the exporting module.
import Exporting from "highcharts/modules/exporting";
import Stock from "highcharts/modules/stock";
import BrokenAxis from "highcharts/modules/broken-axis";
import Accessibility from "highcharts/modules/accessibility";
import ExportData from "highcharts/modules/export-data";
Highcharts.SVGRenderer.prototype.symbols.cross = function (x, y, w, h) {
  return ["M", x, y, "L", x + w, y + h, "M", x + w, y, "L", x, y + h, "z"];
};
if (Highcharts.VMLRenderer) {
  Highcharts.VMLRenderer.prototype.symbols.cross =
    Highcharts.SVGRenderer.prototype.symbols.cross;
}

// Initialize exporting module.
Stock(Highcharts);
Exporting(Highcharts);
BrokenAxis(Highcharts);
Accessibility(Highcharts);
ExportData(Highcharts);

Highcharts.getSVG = function (charts, title = null) {
  var svgArr = [],
    top = 80,
    width = 100,
    endWidth = 0;

  if (!title) {
    top = 0;
  }

  Highcharts.each(charts, function (chart) {
    var svg = chart.getSVG(),
      // Get width/height of SVG for export
      svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*"?(\d+)"?[^>]*>/)[1],
      svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*"?(\d+)"?[^>]*>/)[1];

    svg = svg.replace(
      "<svg",
      '<g transform="translate(' + width + ", " + top + ')" '
    );

    svg = svg.replace("</svg>", "</g>");

    width += svgWidth;
    endWidth = Math.max(endWidth, width);

    if (width === 1 * svgWidth) {
      width = 0;
      top += svgHeight;
    }

    svgArr.push(svg);
  });

  top += 200;

  let svgTitle = "";

  if (title) {
    svgTitle =
      '<text x="300" y="50" font-size="16px" text-anchor="middle">' +
      title +
      "</text>";
  }

  return (
    '<svg height="' +
    600 +
    '" width="' +
    800 +
    '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
    svgTitle +
    svgArr.join("") +
    "</svg>"
  );
};

Highcharts.exportCharts = function (charts, options) {
  var pdf = new jsPDF();
  console.log(pdf, options);
  let svgs = [];
  charts.forEach((chart) => {
    let svg = chart.getSVG({
      chart: {
        width: 1000,
      },
    });
    svgs.push(svg);
  });

  return svgs;
};

export default Highcharts;
